import React from 'react';
import styled from 'styled-components';

const Button = styled.button`
    background-color: ${props => props.buttonColor || 'var(--color-rosa)'};
    border: none;
    border-radius: 8px;
    padding: 16px 48px;
    color: ${props => props.buttonColor ? 'var(--color-background)' : 'var(--color-text)'};
    font-weight: bold;
    cursor: pointer;
    transition: .5s;
`

export default function SubmitButton({children, buttonColor}) {
    return (
        <Button buttonColor={buttonColor}>
            {children}
        </Button>
    )
}